import { Route, Routes } from "react-router-dom";
import Projects from "./components/projects/Projects";
import ProjectDetail from "./components/projectdetail/ProjectDetail";
import Navigation from "./components/navigation/Navigation";
import About from "./components/about/About";
import Centered from "./components/wrappers/Centered";

function App() {
  return (
    <>
      <Navigation />
      <main>
        <Centered>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectDetail />} />
          </Routes>
        </Centered>
      </main>
    </>
  );
}

export default App;
