import { INavItemProps } from "../components/navigation/Navigation";

export interface IData {
  navItems: INavItemProps[];
  projects: IProject[];
}
/** name, url, codelink, languages, summary */
export interface IProject {
  id: string;
  name: projectName;
  url: url;
  codelink: string;
  languages: language[];
  summary: string[];
}

type url =
  | "https://github.com/enkemmc/url2gif/releases/tag/0.1.1"
  | "https://enkemmc.github.io/minesweeper/"
  | "https://enkemmc.github.io/steam_favorites_on_twitch"
  // | "https://api.llyth.net/rust_chat_server/"
  | "https://enkemmc.github.io/rust_chat_server/"
  | "https://enkemmc.github.io/react-flyin-demo"
  | "https://github.com/enkemmc/notification_app/releases"
  | "https://github.com/enkemmc/imdb_api/blob/main/resources/screenshots/client_using_api.gif";

export type language =
  | "Go"
  | "JavaScript"
  | "Java"
  | "React.js"
  | "NodeJs"
  | "TypeScript"
  | "Rust"
  | "Wasm"
  | "Docker"
  | "OAuth2";
export type projectName =
  | "Rocket Chat App"
  | "Minesweeper in Wasm"
  | "Steam Streamer"
  | "Custom IMDB API"
  | "URL2gif";

export const data: IData = {
  projects: [
    {
      id: "chat_app",
      name: "Rocket Chat App",
      languages: ["TypeScript", "React.js", "Rust", "OAuth2"],
      url: "https://enkemmc.github.io/rust_chat_server/",
      codelink: "https://github.com/enkemmc/rust_chat_server/",
      summary: [
        `This is a chat application that features server-sent event messaging, a custom OAuth2 implementation for Github and Google, and a Redis session cache to validate session ids and expirations.  It also supports basic chat features.  I initially built it in Golang, but have since rewritten it in Rust.`,
        `Most recently, I used it to implement OAuth2 and cookie-based authentication using Rust's Rocket framework.`,
      ],
    },
    {
      id: "minesweeper",
      name: "Minesweeper in Wasm",
      languages: ["Wasm", "Rust"],
      url: "https://enkemmc.github.io/minesweeper/",
      codelink: "https://github.com/enkemmc/minesweeper",
      summary: [
        "A simple minesweeper game written in Rust and compiled to Wasm. This project was created to learn more about the current state of browser Wasm APIs and how memory can be shared between Wasm and JavaScript in the browser.",
        "I think that Wasm is an exciting technology that will continue to grow in popularity as more languages are supported and more APIs are exposed to the browser.  I was surprised at how easy the wasm-bindgen crate makes it to integrate Rust into a frontend application.",
      ],
    },
    {
      id: "steam_streamer",
      name: "Steam Streamer",
      languages: ["JavaScript", "React.js", "NodeJs"],
      url: "https://enkemmc.github.io/steam_favorites_on_twitch",
      codelink: "https://github.com/enkemmc/steam_favorites_on_twitch",
      summary: [
        `Quickly find people who are live-streaming video games that you're interested in by providing the link to your public wishlist of games from https://store.steampowered.com/`,
        "This project was among my initial experiences with webscraping. At the time of creation, Steam did not offer a public API to retrieve a user's wishlist. Therefore, the application utilized Regex to extract the profile id from a page source, accessed the Steam public API with this id to request relevant data, and utilized the Twitch API to identify streamers who were playing the games on the wishlist.",
      ],
    },
    {
      id: "imdb_api",
      name: "Custom IMDB API",
      languages: ["JavaScript", "NodeJs", "Docker"],
      url: "https://github.com/enkemmc/imdb_api/blob/main/resources/screenshots/client_using_api.gif",
      codelink: "https://github.com/enkemmc/imdb_api",
      summary: [
        "IMDB doesnt provide a public API, so let's make our own. This project uses docker-compose to orchestrate three containers running a webscraper, a database and an API to access the database.  The goal was to simulate a distributed microservice architecture.",
      ],
    },
    {
      id: "url2gif",
      name: "URL2gif",
      languages: ["Rust", "Docker"],
      url: "https://github.com/enkemmc/url2gif/releases/tag/0.1.1",
      codelink: "https://github.com/enkemmc/url2gif/",
      summary: [
        "URL2gif is a command-line tool for converting URLs to animated GIFs. It uses the headless browser Chromium to capture screenshots of the page and then uses the gif library to create an animated GIF.  This was part of an application that I initially built for work.",
        "The GIFs can grow quite large at higher resolutions and frame counts, but the tool is very easy to use and can capture visuals of any public content on the internet.",
      ],
    },
  ],
  navItems: [
    {
      label: "github",
      href: "https://www.github.com/enkemmc",
    },
    // {
    //   label: "linkedin",
    //   href: "https://www.linkedin.com/in/markenkema"
    // },
    {
      label: "leetcode",
      href: "https://leetcode.com/menkema/",
    },
  ],
};
