import React, { useState, useEffect } from 'react';
import './Projects.css';
import { IProject, language, projectName } from '../../static_data'

import RustLogo from '../../assets/rust.svg'
import NodeJsLogo from '../../assets/nodejs.svg'
import ReactJsLogo from '../../assets/reactjs.svg'
import TypescriptLogo from '../../assets/typescript.svg'
import JavascriptLogo from '../../assets/javascript.svg'
import WasmLogo from '../../assets/wasm.svg'
import DockerLogo from '../../assets/docker.svg'

import ChatScreenshot from '../../assets/chat_screenshot.png'
import SteamStreamerScreenshot from '../../assets/steam_screenshot.png'
import MinesweeperScreenshot from '../../assets/minesweeper_screenshot.png'
import URL2GifScreenshot from '../../assets/url2gif_screenshot.png'

import { data } from '../../static_data/'

const Grid: React.FC = () => { // children
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <div className="grid-container pt-28 lg:pt-36">
      {data.projects.map((project, i) => <GridCard key={i} project={project} isSmallScreen={isSmallScreen} />)}
    </div>
  )
}

interface IGridCard {
  project: IProject
  isSmallScreen: boolean
}

export const GridCard: React.FC<IGridCard> = ({ project, isSmallScreen }) => {
  const { name, url, codelink, languages, summary } = project
  return (
    <div className="grid-card">
      <div className="grid-text">
        <a href={url}>
          <div className="grid-title lg:hidden w-full mb-6">
            {name}
          </div>
        </a>
        <div className="grid-top-wrapper">
          <div className="grid-left">
            <a href={url}>
              <div className="grid-title hidden lg:block">
                {name}
              </div>
            </a>
            <div className="grid-tools">
              <ul>
                {languages.map((language, i) => (
                  <li key={`${name}-language-${i}`} className="tool-row">
                    <span>{language}</span>
                    <ToolIcon language={language} />
                  </li>
                ))}
              </ul>
            </div>
            {isSmallScreen && (
              <div className="grid-button-container">
                <Button href={url}>Demo</Button>
                <Button href={codelink}>Code</Button>
              </div>
            )}
          </div>
          <div className="grid-right">
            <ProjectImage projectname={name} />
          </div>
        </div>
        <div className="grid-summary">
          {summary.map((para, i) => (
            <p key={`${name}-summary-${i}`}>{para}</p>
          ))}
        </div>
        {!isSmallScreen && (
          <div className="grid-button-container">
            <Button href={url}>Demo</Button>
            <Button href={codelink}>Code</Button>
          </div>
        )}
      </div>
      <div className="grid-image-wrapper">
        <ProjectImage projectname={name} />
      </div>
    </div>
  )
}

interface IButton {
  href: string
  children: React.ReactNode
}

const Button: React.FC<IButton> = ({ children, href }) => {
  return (
    <a href={href} target="_blank">
      <button className="grid-button">{children}</button>
    </a>
  )
}

interface IToolIcon {
  language: language
}

const ToolIcon: React.FC<IToolIcon> = ({ language }) => {
  switch (language) {
    case 'NodeJs':
      return <img className="tool-logo" src={NodeJsLogo} />
    case 'Rust':
      return <img className="tool-logo" src={RustLogo} />
    case 'React.js':
      return <img className="tool-logo" src={ReactJsLogo} />
    case 'TypeScript':
      return <img className="tool-logo" src={TypescriptLogo} />
    case 'JavaScript':
      return <img className="tool-logo" src={JavascriptLogo} />
    case 'Wasm':
      return <img className="tool-logo" src={WasmLogo} />
    case 'Docker':
      return <img className="tool-logo" src={DockerLogo} />
    default:
      return <span />
  }
}

interface IProjectImage {
  projectname: projectName
}

const ProjectImage: React.FC<IProjectImage> = ({ projectname }) => {
  switch (projectname) {
    case 'Minesweeper in Wasm':
      return <img className="grid-image shadow" alt="screenshot" src={MinesweeperScreenshot} />
    case 'Rocket Chat App':
      return <img className="grid-image shadow" alt="screenshot" src={ChatScreenshot} />
    case 'Steam Streamer':
      return <img className="grid-image shadow" alt="screenshot" src={SteamStreamerScreenshot} />
    case 'Custom IMDB API':
      return <img className="grid-image shadow" style={{ backgroundColor: '#fffcfc' }} alt="screenshot" src='https://raw.githubusercontent.com/enkemmc/imdb_api/main/resources/screenshots/client_using_api.gif' />
    case 'URL2gif':
      return <img className="grid-image shadow" style={{ backgroundColor: '#0c0b0b' }} alt="screenshot" src={URL2GifScreenshot} />
    default:
      return <span />
  }
}


export default Grid;
