import { useState, useEffect } from "react";
import "./Navigation.css";
import Logo from "./Logo";
import LinkedInLogo from "../../assets/linkedin_logo.png";
import GithubLogo from "../../assets/github_logo.svg";
import LeetcodeLogo from "../../assets/leetcode_logo.svg";
import Moon from "../../assets/moon.png";
import Sun from "../../assets/sun.svg";
import { NavLink } from "react-router-dom";

import { data } from "../../static_data";

export interface INavItemProps {
  label: string;
  href: string;
}

const Navigation: React.FC = () => {
  // children
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="navigation-container shadow">
      <Logo />
      <div className="padding" />
      <nav className="site-nav">
        <input type="checkbox" id="nav-trigger" className="nav-trigger" />
        <label htmlFor="nav-trigger">
          <span className="menu-icon">
            <svg viewBox="0 0 18 15" width="18px" height="15px">
              <path d="M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.032C17.335,0,18,0.665,18,1.484L18,1.484z M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.032C17.335,6.031,18,6.696,18,7.516L18,7.516z M18,13.516C18,14.335,17.335,15,16.516,15H1.484 C0.665,15,0,14.335,0,13.516l0,0c0-0.82,0.665-1.483,1.484-1.483h15.032C17.335,12.031,18,12.695,18,13.516L18,13.516z"></path>
            </svg>
          </span>
        </label>
        <div className="trigger">
          <div className="nav-item">
            <NavLink className="nav-link-text" to="/">
              About
            </NavLink>
          </div>
          <ProjectsDropdown />
          {/* <CustomLogo src={LinkedInLogo} text="LinkedIn" href="https://www.linkedin.com/in/markenkema/" isSmallScreen={isSmallScreen} /> */}
          <CustomLogo
            src={GithubLogo}
            text="Github"
            href="https://github.com/enkemmc/"
            invert
            isSmallScreen={isSmallScreen}
          />
          <CustomLogo
            src={LeetcodeLogo}
            text="Leetcode"
            href="https://leetcode.com/menkema/"
            isSmallScreen={isSmallScreen}
          />
          <LightDark isSmallScreen={isSmallScreen} />
        </div>
      </nav>
    </div>
  );
};

const ProjectsDropdown = () => {
  return (
    <div className="nav-item projects">
      <NavLink className="nav-link-text" to="/projects">
        Projects
      </NavLink>
      <ul className="sub-menu">
        {data.projects.map(({ id, name }) => (
          <li>
            <NavLink to={`/projects/${id}`}>{name}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

const detectLightMode = () => {
  return (
    (window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches) ||
    sessionStorage.getItem("lightMode")
  );
};

interface ILightDark {
  isSmallScreen: boolean;
}

const LightDark: React.FC<ILightDark> = ({ isSmallScreen }) => {
  const [isLightMode, setLightMode] = useState(true);

  const toggleColors = () => {
    const root = document.documentElement;
    const colorOne = getComputedStyle(root).getPropertyValue("--secondary");
    const colorTwo = getComputedStyle(root).getPropertyValue("--secondary-alt");
    const colorThree = getComputedStyle(root).getPropertyValue("--primary");
    const colorFour = getComputedStyle(root).getPropertyValue("--primary-alt");

    root.style.setProperty("--secondary", colorTwo);
    root.style.setProperty("--secondary-alt", colorOne);
    root.style.setProperty("--primary", colorFour);
    root.style.setProperty("--primary-alt", colorThree);
    setLightMode((prev) => {
      if (prev) {
        sessionStorage.removeItem("lightMode");
      } else {
        sessionStorage.setItem("lightMode", "yes");
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      toggleColors();
    } else {
      sessionStorage.removeItem("lightMode");
    }
  }, []);

  return (
    <div onClick={toggleColors} className={`nav-item animate`}>
      <a>
        {isSmallScreen && <>{isLightMode ? "Dark mode" : "Light mode"}</>}
        {!isSmallScreen &&
          (isLightMode ? (
            <img src={Sun} className={"nav-logo"} alt="Light mode toggle" />
          ) : (
            <img src={Moon} className={"nav-logo"} alt="Dark mode toggle" />
          ))}
      </a>
    </div>
  );
};

interface CustomLogoProps {
  src: string;
  text: string;
  href: string;
  invert?: boolean;
  isSmallScreen: boolean;
}

const CustomLogo: React.FC<CustomLogoProps> = ({
  src,
  text,
  href,
  invert,
  isSmallScreen,
}) => {
  return (
    <div className="nav-item animate">
      <a href={href} target="_blank">
        {isSmallScreen ? (
          <>{text}</>
        ) : (
          <img
            src={src}
            className={`${invert ? "invert " : ""}nav-logo`}
            alt={`${text} Logo`}
          />
        )}
      </a>
    </div>
  );
};

export default Navigation;
