import React from 'react'
import ProfilePic from '../../assets/profile_pic.jpg'
import './About.css'

const About = () => {
  return (
    <div className="col-centered">
      <div className="navigation-height" />
      <div className="about">
        <div className="about-top">
          <Pic />
          <AnimatedSkills />
        </div>
        <Intro />
      </div>
    </div>
  )
}

const Pic = () => {
  return (
    <div className="profile-pic">
      <img className="" src={ProfilePic} />
    </div>
  )
}

const Meta = () => {
  return (
    <a href="https://about.meta.com/" target="_blank">Meta</a>
  )
}

const ATT = () => {
  return (
    <a href="https://about.att.com/" target="_blank">AT&T</a>
  )
}

const Intro = () => {
  return (
    <div className="introduction">
      <p>
        Hello! My name is Mark Enkema and I love helping businesses solve problems using technology.  I consider myself a tech generalist and pride myself on my ability to provide value in a variety of roles and technology stacks.
      </p>
      <p>
        I've spent the last 14 years working as a Technical PM, Team Lead and Software Engineer at companies such as <ATT/> and <Meta/>. While my expertise lies predominantly in JavaScript-based technologies and the cloud, I am always learning new tools and technologies. Recently, I have developed a fascination with the Rust programming language, with a focus on integrating AI into my work process.
      </p>
      <p>
        If you're interested in learning more about my experience or discussing potential opportunities, please don't hesitate to reach out.  I look forward to connecting with you!
      </p>
    </div>
  )
}

const AnimatedSkills = () => {
  return (
    <div className="skills">
      <ul>
        <li>Git</li>
        <li>Rust</li>
        <li>Docker</li>
        <li>Node.js</li>
        <li>React.js</li>
        <li>TypeScript</li>
        <li>Cloud Deployment</li>
      </ul>
    </div>
  )
}

export default About
