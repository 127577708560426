import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { data } from '../../static_data'
import { GridCard } from '../projects/Projects'
import './ProjectDetail.css'


const ProjectDetail = () => {
  const { id } = useParams()
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const project = data.projects.find(p => p.id === id)
  return (
    <div className="one-off">
      <GridCard project={project!} isSmallScreen={isSmallScreen} />
    </div>
  )
}

export default ProjectDetail
